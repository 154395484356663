import React from "react";
import { graphql } from "gatsby";
import { imageUrlFor } from "../lib/image-url";
import { buildImageObj } from "../lib/helpers";
import Errors from "../components/errors";
import SEO from "../components/seo";
import Layout from "../containers/layout";

import LocationHours from "../components/location-hours"
import GrizzlyLook from "../components/grizzly-look";
import Skew from "../components/skew";
import PortableText from "../components/portableText";

export const query = graphql`
  query AcademyPageQuery {
    page: sanityAcademyPage(_id: { regex: "/(drafts.|)academy/" }) {
      title
      email
      _rawDescription(resolveReferences: {maxDepth: 10})
      image {
        ...SanityImage
      }
      locationImage {
        ...SanityImage
      }
      address {
        street
        town
        county
        postcode
      }
      tel
      openingHours
    }

    site: sanitySiteSettings(_id: { regex: "/(drafts.|)siteSettings/" }) {
      name
      announcement {
        enabled
      }
      ...NavMenu
    }
  }
`;

const IndexPage = props => {
  const { data, errors } = props;

  if (errors) {
    return <Errors errors={errors} />;
  }

  const site = (data || {}).site;

  if (!site) {
    throw new Error(
      'Missing "Site settings". Open the studio at http://localhost:3333 and add some content to "Site settings" and restart the development server.'
    );
  }

  const page = data.page || data.route.page;
  const img = imageUrlFor(buildImageObj(page?.image))
    .width(1600)
    .url()
  const locationImg = imageUrlFor(buildImageObj(page?.locationImage))
    .width(600)
    .url()
  const menuItems = site.navMenu && (site.navMenu.items || [])
  const hasAnnouncement = site.announcement.enabled

  return (
    <Layout navMenuItems={menuItems} nav={site.navMenu} textWhite={true} >
      <SEO
        title={page.title}
        bodyAttr={{
          class: "leading-normal tracking-normal text-white bg-gray-800"
        }}
      />
      <div className={`${hasAnnouncement ? 'md:pt-40' : 'md:pt-24'}`}>
        <section style={{ backgroundImage: `url('${img}')`, backgroundRepeat: 'no-repeat', backgroundPosition: 'center', backgroundSize: 'cover', height: '65vh' }} className="relative text-white text-center">
          <div className="border-box items-center p-12 z-20 min-w-min border-2 bg-gray-900 bg-opacity-50 absolute top-1/2 left-1/2 transform -translate-y-1/2 -translate-x-1/2">
            <h1 className="text-5xl uppercase text-white">{page.title}</h1>
          </div>
        </section>
        <Skew />

        <section className="py-12 px-6 md:p-24 justify-center text-center flex">
          <PortableText
            blocks={page._rawDescription}
            className="grid gap-3"
          />
        </section>

        <section className="py-32">
          <LocationHours
            address={page.address}
            tel={page.tel}
            hours={page.openingHours}
            image={locationImg}
            email={page.email}
          />
        </section>

        <GrizzlyLook />
      </div>
    </Layout >
  );
};

export default IndexPage;
