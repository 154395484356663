import React from "react";
import MainImage from "./MainImage";
import BlockContent from '@sanity/block-content-to-react'
import ReactPlayer from "react-player";
import InstagramEmbed from "react-instagram-embed";
import { Heading } from "./headings";

const BlockList = (props) => {
  return <div className="text-left flex w-1/2 m-auto">
    <ul className={`self-center ${props.type === 'bullet' ? 'list-disc' : 'list-decimal'}`}>
      {props.children}
    </ul>
  </div>;
};

const BlockListItem = (node) => {
  return <li className="">
    {node.children[0].text}
  </li>;
};

const BlockRenderer = (props) => {
  const {style = 'normal'} = props.node

  if (/^h\d/.test(style)) {
    return <div className="pb-4">
      <Heading text={props.children} />
    </div>
  }

  if (style === 'blockquote') {
    return <blockquote>- {props.children}</blockquote>
  }

  // Fall back to default handling
  return BlockContent.defaultSerializers.types.block(props)
}

const serializers = {
  types: {
    mainImage: ({ node }) => <MainImage mainImage={node} />,
    videoEmbed: ({ node }) => <ReactPlayer className="mt-6 mb-6" url={node.url} controls />,
    instagram: ({ node }) => {
      if (!node.url) return null;
      return <InstagramEmbed url={node.url} className="container mx-auto mt-6 mb-6" />;
    },
    block: BlockRenderer
  },
  list: (prop) => <BlockList {...prop} />,
  listItem: ({ node }) => <BlockListItem {...node} />
};

export default serializers;
